import React from 'react'

export default class userinfo extends React.Component {
    render() {
        return (
            <div>
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"col"}><h1>Deine Übersicht</h1></div>
                    </div>
                </div>
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"col"}>

                        </div>
                        <div className={"col"}>
                            </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col"}></div>
                        <div className={"col"}></div>
                    </div>
                </div>
            </div>
        );
    }
}