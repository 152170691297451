import React from 'react'
import {withCookies} from "react-cookie";
import { NavLink } from "react-router-dom";

class login extends React.Component {

    render() {
        return (
            <div className={"container-fluid"}>

                <div className={"row"}>
                    <div className={"col h2"}><p>Kommen sie rein :)</p></div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <form>
                            <div className="mb-3">
                            <label className={"form-label"}>Du bist bereits Mitglied bei Shop37? <br/> Dann log dich ein </label><br/>
                            <button className={"btn btn-outline-primary btn-lg"} type="submit" id={"anmeldenButton"}>
                                Anmelden
                            </button>
                            </div>
                        </form>
                    </div>
                    <div className={"col-md-6"}>
                       <form action={"/registerform"}>
                           <div className="mb-3">
                           <label className={"form-label"}>
                               Du willst Deine Chance nutzen und Deinen Kaufpreis von uns mit einer Chance von 1:36 geschenkt erhalten?
                           </label>
                           <button className={"btn btn-outline-primary  btn-lg"} type={"submit"} id={"registrierenButton"}>Registrieren</button>
                           </div>
                       </form>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <form action={"https://google.com"}>
                            <div className="mb-3">
                                <label className={"form-label"}>Du bist kein Mitglied und willst es auch nicht werden?</label><br/>
                                <button className={"btn btn-outline-primary  btn-lg"} type={"submit"} id={"registrierenButtonTest"}>Dann gehe direkt zum Produkt</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                            <div className="mb-3">
                                <label className={"form-label"}>Hintertürchen zum Basteln</label><br/>
                                <NavLink to={"/register"} ><button className={"btn btn-outline-primary"} id={"registrierenButtonTest"}>DirektLogin</button></NavLink>
                            </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCookies(login)