import React from "react";
import ToolBox from "../helper/ToolBox";


interface BrandViewState{
    isLoaded: boolean;
    items: [];
    error: string;
    oldProp: string;
}

interface BrandProps {
    onChangeBrand : (e: React.ChangeEvent<HTMLSelectElement>) => void;
    categorie: string
}

var baseUrl = process.env.REACT_APP_BACKEND_SERVER

var toolbox= new ToolBox()
export default class BrandSelecotr extends React.Component <BrandProps, BrandViewState> {

    constructor(props: BrandProps) {
        super(props);
        this.state = {
            error: "", items: [], isLoaded: false, oldProp:""};
    }

    reloadListe = () => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: this.props.categorie
        };
        if (toolbox.isFeatureAllowed('REACT_APP_FEATURE_SHOP')) {

            fetch(baseUrl+"/product/brands", requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result)
                        this.setState({
                            isLoaded: true,
                            items: result
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: false,
                            error
                        });
                    }
                )
        };
    }


    componentDidMount() {

            this.reloadListe();

    }

    componentDidUpdate(prevProps: Readonly<BrandProps>, prevState: Readonly<BrandViewState>, snapshot?: any) {
        if (this.state.oldProp !== this.props.categorie) {
            this.reloadListe();
            this.setState({
                oldProp: this.props.categorie
            });
        }
    }

    render() {
        return (
            <div>
                <form className={"form-floating"}>
                    <select id={"brandSelect"} className="form-select form-select-sm" aria-label=".form-select-sm example" onChange={this.props.onChangeBrand}>
                        <option value={""}>Alle</option>
                        {toolbox.isFeatureAllowed('REACT_APP_FEATURE_SHOP') ?
                            this.state.isLoaded && this.state.items.length > 0 ?
                                this.state.items.map(item => <option key={item} value={item}>{item}</option> ) : null : <option value={"no brands"}>No Brands</option>
                        }
                    </select>
                    <label htmlFor={"brandSelect"}>Wähle dein Brand</label>
                </form>
            </div>)
    }
}