import React from 'react'
import AdressForm from '../components/adressComp'
import {NavLink} from "react-router-dom";

export default class registrationform extends React.Component {


    changeHandler() {
        alert("Hallo Welt")
    }

    render() {

        return (
            <div className={"container-fluid"}>
                <div className={"row align-items-start"}>
                    <div className={"col-md-2"}></div>
                    <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}><h1>Teilnahme
                        Registrierung</h1></div>
                    <div className={"col-md-2"}></div>
                </div>

                <form action={"/sendit"}>
                    <div className={"row align-items-start"}>
                        <div className={"col-md-2"}></div>
                        <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}>
                            <div className={"h3"}>Willkommen</div>
                            <p>Als Dank dafür, dass Du über shop37 konsumierst, schenken wir jedem 37zigsten Käufer nach
                                den folgenden <NavLink to={"/teilnahme"} target={"blank"}> Teilnahmebedingungen</NavLink> den
                                Kaufpreis! Deine Chance auf den Kaufpreis steht also bei 1:36.
                                Machst Du mit?</p>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="MitSpielen"
                                       id="MitSpielenJa" onChange={this.changeHandler}/>
                                <label className="form-check-label" htmlFor="MitSpielenJa">
                                    Ja
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="MitSpielen"
                                       id="MitSpielenNein" checked onChange={this.changeHandler}/>
                                <label className="form-check-label" htmlFor="MitSpielenNein">
                                    Nein
                                </label>
                            </div>

                            <div>
                                <p>
                                    Ich möchte die Chance nach den von mir hiermit eingesehenen und akzeptierten
                                    Teilnahmebedingungen nutzen, meinen Kaufpreis von shop37 geschenkt zu erhalten!
                                    [opt-in]
                                </p>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="Teilnahme"
                                           id="TeilnahmeBedingungJa" onChange={this.changeHandler}/>
                                    <label className="form-check-label" htmlFor="TeilnahmeBedingungJa">
                                        Ja
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="Teilnahme"
                                           id="TeilnahmeBedingungNein" checked onChange={this.changeHandler}/>
                                    <label className="form-check-label" htmlFor="TeilnahmeBedingungNein">
                                        Nein
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-2"}></div>
                    </div>
                    <div>

                        <div className={"row align-items-start"}>
                            <div className={"col-md-2"}></div>
                            <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}>
                                <AdressForm/>

                            </div>
                            <div className={"col-md-2"}></div>
                        </div>
                        <div className={"row align-items-start"}>
                            <div className={"col-md-2"}></div>
                            <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}>
                                <div className={"h3"}>Finanzdaten für Auszahlung</div>
                                <div className="input-group mb-3">
                                    <select className="form-select" id="Kontotyp" defaultValue="null">
                                        <option value="null">Kontotyp?</option>
                                        <option value="IBAN">IBAN</option>
                                        <option value="PAYPAL">PAYPAL</option>
                                    </select>
                                    <label className="input-group-text" htmlFor="inputGroupSelect01">-</label>
                                    <input type="text" className="form-control" placeholder="Finanzdaten"
                                           aria-label="Name" id={"Finanzdaten"}/>


                                </div>
                            </div>
                            <div className={"col-md-2"}></div>
                        </div>

                        <div className={"row align-items-start"}>
                            <div className={"col-md-2"}></div>
                            <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}>
                                <div className={"h3"}>Zur Kenntnisnahme</div>
                                <p>Ja, ich will die Chance nutzen und an der Auslosung der Kaufpreisschenkung nach
                                    den <NavLink to={"/teilnahme"} target={"blank"}> Teilnahmebedingungen</NavLink> und den <a
                                        href={"/datenschutz"} target={"blank"}>Datenschutzbestimmungen</a>, die ich
                                    -jede für sich- gelesen und hiermit akzeptiert habe, teilnehmen! </p> <p>Sobald eine
                                Auslosung nach den Teilnahmebedingungen erfolgt ist und die Voraussetzungen dieser
                                Teilnahme Bedingungen vollständig erfüllt sind, werde ich im Falle der Beschenkung von
                                shop37 per mail informiert.
                            </p>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="MailCheck"/>
                                    <label className="form-check-label" htmlFor="MailCheck">
                                        ich erlaube den check meiner Emailadresse
                                    </label>
                                </div>
                                <br/><br/>
                                <p>
                                    Ich stimme hiermit jederzeit widerruflich zu, dass mich shop37 im Falle der
                                    Kaufpreisschenkung hierüber per mail informieren darf.
                                </p>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="Mailinfo"/>
                                    <label className="form-check-label" htmlFor="Mailinfo">
                                        Shop37 darf mir Informationsmails zustellen.
                                    </label>
                                </div>
                                <br/><br/>

                            </div>
                            <div className={"col-md-2"}></div>
                        </div>

                        <div className={"row align-items-start"}>
                            <div className={"col-md-2"}></div>
                            <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}>
                                <button className={"btn btn-outline-primary btn-lg"} type={"submit"} >Registrieren</button>

                            </div>
                            <div className={"col-md-2"}></div>
                        </div>

                    </div>
                </form>
            </div>
        );
    }
}
