import React from 'react'
import order from '../pic/order.svg'
import register from '../pic/Register.svg'
import payout from '../pic/payout.svg'

export default class sogehts extends React.Component {

    render() {
        return (
            <div className={"container-fluid"}>
                <div className={"row align-items-start"}>
                    <div className={"col-md-2"}></div>
                    <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}><h1>So gehts</h1></div>
                    <div className={"col-md-2"}></div>
                </div>
                <div className={"row align-items-start"}>
                    <div className={"col-md-2"}></div>
                    <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}>

                        <ul className="list-group list-group-numbered">
                            <li className="list-group-item d-flex justify-content-between align-content-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Anmeldung</div>
                                    <div>Melden Sie sich jetzt an auf <a href={"https://www.Shop37.de"}>www.Shop37.de</a></div>
                                    <div className="fw-bold">Bestellen</div>
                                    <div>Wählen Sie über Shop37 ihr gewünschtes Produkt und bestellen Sie Ihr Produkt.
                                        Ihnen wird nun eine Zahl zwischen 0 und 36 zugewiesen.
                                    </div>
                                    <div className="fw-bold">Ziehung</div>
                                    <div>Nach der Bestellung werden zwischen 37 Teilnehmern eine Ziehung stattfinden,
                                        die anonym und unbeeinflusst, online durchgeführt wird.
                                    </div>
                                    <div className="fw-bold">Auszahlung</div>
                                    <div>Wird die Ihnen zugeordnete Zahl gezogen, kriegen Sie ihren Einkaufswert
                                        geschenkt
                                    </div>
                                </div>
                                <img src={register} width={100} alt={register}/>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-content-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Erklärvideo</div>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Us7zEOuJmUA"
                                            title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen></iframe>

                                </div>
                                <img src={order} width={100} alt={order}/>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-content-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Teilnahmebedingung ganz kurz</div>
                                    <ul>
                                        <li>Der Kunde muss die Ware bestellen und bezahlen.</li>
                                        <li>Der Kunde kommt nur in die Ziehung, wenn das Widerrufsrecht erlöschen worden
                                            ist.
                                        </li>
                                        <li>Nach Ablauf des Widerrufsrechts, kommt der Teilnehmer in die Ziehung. Bei
                                            der Ziehung müssen sich weitere 36 Teilnehmer befinden. Nachdem der Pool mit
                                            37 Teilnehmer gefüllt ist, wird ein Ziehung veranlasst. Es gibt nur immer
                                            ein Gewinner von 37 Teilnehmer. Die Ziehung findet innerhalb 24 Stunden
                                            statt.
                                        </li>
                                        <li>Nachdem ein Teilnehmer gewonnen hat, wird der Gewinner von uns per E-Mail
                                            informiert. Nachdem wird das Geld von unserem Partner erhalten haben, wird
                                            das Geld innerhalb 72 Stunden auf das gewünschte Konto überwiesen.
                                        </li>
                                    </ul>
                                </div>
                                <img src={payout} width={100} alt={order}/>
                            </li>
                        </ul>

                    </div>
                    <div className={"col-md-2"}></div>
                </div>
            </div>
        );
    }
}