import React from 'react'
import {instanceOf} from "prop-types";
import {Cookies, ReactCookieProps, withCookies} from "react-cookie";
import {Redirect} from "react-router-dom";

class registration extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    constructor(props: ReactCookieProps) {
        super(props);

        const { cookies } = props;

        cookies?.set('usrprops', "MeineFresse1", { path: '/' });
        this.state = {
            usrprops: cookies?.get('usrprops') || 'gugus'
        };
    }
    render() {
        return (<Redirect to="/userinfo" />);
    }
}

export default withCookies(registration)