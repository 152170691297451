import React from 'react'

export default class adressform extends React.Component {


    render() {

        return (
            <div>
                <div className={"h3"}>Kontaktdaten</div>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="Emailadresse">@</span>
                    <input type="email" className="form-control" placeholder="Emailadresse" aria-label="Emailadresse"
                           aria-describedby="basic-addon1" id={"Emailadresse"}/>
                </div>

                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Vorname"
                           aria-label="Vorname" id={"Vorname"}/>
                    <span className="input-group-text">und</span>
                    <input type="text" className="form-control" placeholder="Name"
                           aria-label="Name" id={"Name"}/>
                </div>

                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Strasse"
                           aria-label="Strasse" id={"Strasse"}/>
                    <span className="input-group-text">und</span>
                    <input type="text" className="form-control" placeholder="Hausnummer"
                           aria-label="Name" id={"Hausnummer"}/>
                </div>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="PLZ"
                           aria-label="Strasse" id={"PLZ"}/>
                    <span className="input-group-text">-</span>
                    <input type="text" className="form-control" placeholder="Ort"
                           aria-label="Name" id={"Ort"}/>
                    <label className="input-group-text" htmlFor="inputGroupSelect01">Land</label>
                    <select className="form-select" id="Land" defaultValue={"null"}>
                        <option value="null">Land?</option>
                        <option value="DE">DE</option>
                        <option value="CH">CH</option>
                        <option value="AT">AT</option>
                    </select>
                </div>
            </div>
        );
    }
}