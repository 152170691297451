import React from 'react'
import {ReactCookieProps, withCookies} from "react-cookie";
import ProducItme from '../components/productItem'
import logo from "../pic/shop37_474.png";
import Product from "../MyClasses/ProductClass";
import ToolBox from "../helper/ToolBox";
import BrandSelector from "../components/BrandSelector"
import CategorieSelector from "../components/CategorieSelector";

interface ProdOverViewState {
    loaded: boolean;
    items: [Product];
    error: string;
    brandAndCatFilter: {
        brand: string;
        categorie: string;
        startPosition: number;
        resultSize: number;
    }
}

var toolbox= new ToolBox()

var somethinUpdated = false

var baseUrl = process.env.REACT_APP_BACKEND_SERVER

class productoverview extends React.Component <ReactCookieProps, ProdOverViewState> {

    constructor(props: ReactCookieProps) {
        super(props);
        this.state = {
            brandAndCatFilter: {
                brand: "",
                categorie: "",
                startPosition: 0,
                resultSize: 10
            },
            error: "", items: [{
                timestamp: 'test',
                uudi: 'test',
                id: 'test',
                promotion_ID: 'test',
                shipping_LABEL: 'test',
                shipping_WEIGHT: 'test',
                shipping_LENGTH: 'test',
                shipping_WIDTH: 'test',
                shipping_HEIGHT: 'test',
                min_HANDLING_TIME: 'test',
                max_HANDLING_TIME: 'test',
                program_NAME: 'test',
                program_URL: 'test',
                catalog_NAME: 'test',
                title: 'test',
                link: 'test',
                impression_URL: 'test',
                image_LINK: 'test',
                mobile_LINK: 'test',
                additional_IMAGE_LINK: 'test',
                availability: 'test',
                availability_DATE: 'test',
                expiration_DATE: 'test',
                price: 'test',
                sale_PRICE: 'test',
                sale_PRICE_EFFECTIVE_DATE: 'test',
                unit_PRICING_MEASURE: 'test',
                unit_PRICING_BASE_MEASURE: 'test',
                installment: 'test',
                loyalty_POINTS: 'test',
                google_PRODUCT_CATEGORY: 'test',
                google_PRODUCT_CATEGORY_NAME: 'test',
                product_TYPE: 'test',
                brand: 'test',
                gtin: 'test',
                mpn: 'test',
                identifier_EXISTS: 'test',
                conditions: 'test',
                adult: 'test',
                multipack: 'test',
                is_BUNDLE: 'test',
                energy_EFFICIENCY_CLASS: 'test',
                age_GROUP: 'test',
                color: 'test',
                gender: 'test',
                material: 'test',
                pattern: 'test',
                size: 'test',
                size_TYPE: 'test',
                size_SYSTEM: 'test',
                item_GROUP_ID: 'test',
                custom_LABEL0: 'test',
                custom_LABEL1: 'test',
                custom_LABEL2: 'test',
                custom_LABEL3: 'test',
                custom_LABEL4: 'test',
                last_UPDATED: 'test',
                description: 'test',
            }],
            loaded: false
        };
    }



    componentDidMount() {

        this.updateItems()
    }


    componentDidUpdate(prevProps: Readonly<ReactCookieProps>, prevState: Readonly<ProdOverViewState>, snapshot?: any) {
       if(somethinUpdated){
           this.updateItems()
           somethinUpdated = false
       }

    }


    filterToBrand = (e: React.ChangeEvent<HTMLSelectElement>) => {
        somethinUpdated = true
        this.setState({
            brandAndCatFilter: {
                brand: e.target.value,
                categorie: this.state.brandAndCatFilter.categorie,
                startPosition: this.state.brandAndCatFilter.startPosition,
                resultSize: this.state.brandAndCatFilter.resultSize
            }
        });
    }

    filterToCategorie = (e: React.ChangeEvent<HTMLSelectElement>) => {
        somethinUpdated = true
        this.setState({
            brandAndCatFilter: {
                brand: this.state.brandAndCatFilter.brand,
                categorie: e.target.value,
                startPosition: this.state.brandAndCatFilter.startPosition,
                resultSize: this.state.brandAndCatFilter.resultSize
            }
        });
    }

    updateItems = () => {

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(this.state.brandAndCatFilter)
        };

        if (toolbox.isFeatureAllowed('REACT_APP_FEATURE_SHOP')) {
            fetch(baseUrl + "/product/filter", requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState(
                            {loaded: true,
                                items: result});
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            loaded: false,
                            error
                        });
                    }
                )
        }
    }

    setSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
        somethinUpdated = true;
        this.setState({
            brandAndCatFilter: {
                brand: this.state.brandAndCatFilter.brand,
                categorie: this.state.brandAndCatFilter.categorie,
                startPosition: this.state.brandAndCatFilter.startPosition,
                resultSize: Number(e.target.value)
            }
        })
    }

    render() {
        return (
            <div className={"container-fluid"} id={"productoverview"}>
                <div className={"row"}>
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-md-3"}>

                            <div ><BrandSelector categorie={this.state.brandAndCatFilter.categorie} onChangeBrand={this.filterToBrand}/></div>
                            </div>
                            <div className={"col-md-6"}>
                            <div ><CategorieSelector brand={this.state.brandAndCatFilter.brand} onChangeCat={this.filterToCategorie}/></div>
                            </div>
                            <div className={"col-md-2"}>
                                <form className={"form-floating"}>
                            <select id={"sizeSelector"} className="form-select form-select-sm" aria-label=".form-select-sm example" onChange={this.setSize}>
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                                    <label htmlFor={"sizeSelector"}>Anzahl Angebot pro Seite</label>
                            </form>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={"row row-cols-auto"}>
                    {toolbox.isFeatureAllowed('REACT_APP_FEATURE_SHOP') ?
                        this.state.loaded && this.state.items.length > 0 ?
                            this.state.items.map(item => (<div key={item.uudi}>
                                    <ProducItme description={item.description}
                                                link={item.link}
                                                picture={item.image_LINK}
                                                shop={item.brand}
                                                titel={item.title}
                                                id={item.id}
                                                price={item.price}
                                    ></ProducItme>
                                </div>)
                            )
                            : null
                        :
                        <div className={"row row-cols-auto"}>
                            <div>
                                <ProducItme id={"Abdsdsdf"}
                                            description={"Hallo Welt"}
                                            link={"https://www.codingfarm.org"}
                                            picture={logo}
                                            shop={"Avus"}
                                            titel={"Hallo Welt"}
                                            price={"20.- CHF"}
                                ></ProducItme>
                            </div>
                            <div>
                                <ProducItme id={"dwrfehrg"}
                                            description={"Hallo Welt"}
                                            link={"https://www.codingfarm.org"}
                                            picture={logo}
                                            shop={"Avus"}
                                            titel={"Hallo Welt"}
                                            price={"20.- CHF"}
                                ></ProducItme>
                            </div>
                            <div>
                                <ProducItme description={"Hallo Welt"}
                                            link={"https://www.codingfarm.org"}
                                            picture={logo}
                                            shop={"Avus"}
                                            titel={"Hallo Welt"}
                                            id={"sarghgghf"}
                                            price={"20.- CHF"}
                                ></ProducItme>
                            </div>
                            <div>
                                <ProducItme description={"Hallo Welt"}
                                            link={"https://www.codingfarm.org"}
                                            picture={logo}
                                            shop={"Avus"}
                                            titel={"Hallo Welt"}
                                            id={"vhndfshfgjzj"}
                                            price={"20.- CHF"}
                                ></ProducItme>
                            </div>
                            <div>
                                <ProducItme description={"Hallo Welt"}
                                            link={"https://www.codingfarm.org"}
                                            picture={logo}
                                            shop={"Avus"}
                                            titel={"Hallo Welt"}
                                            id={"rwtzeuritokrzejthsrg"}
                                            price={"20.- CHF"}
                                ></ProducItme>
                            </div>
                            <ProducItme description={"Hallo Welt"}
                                        link={"https://www.codingfarm.org"}
                                        picture={logo}
                                        shop={"Avus"}
                                        titel={"Hallo Welt"}
                                        id={"aertwzeuzlietuksjfgh"}
                                        price={"20.- CHF"}
                            ></ProducItme>
                            <div>
                                <ProducItme description={"Hallo Welt"}
                                            link={"https://www.codingfarm.org"}
                                            picture={logo}
                                            shop={"Avus"}
                                            titel={"Hallo Welt"}
                                            id={"uztiozuktjdhtg"}
                                            price={"20.- CHF"}
                                ></ProducItme>
                            </div>
                            <div>
                                <ProducItme description={"Hallo Welt"}
                                            link={"https://www.codingfarm.org"}
                                            picture={logo}
                                            shop={"Avus"}
                                            titel={"Hallo Welt"}
                                            id={"sdfghdjfki6tezurhsdtg"}
                                            price={"20.- CHF"}
                                ></ProducItme>
                            </div>
                            <div>
                                <ProducItme description={"Hallo Welt"}
                                            link={"https://www.codingfarm.org"}
                                            picture={logo}
                                            shop={"Avus"}
                                            titel={"Hallo Welt"}
                                            id={"awrtzjshjurz"}
                                            price={"20.- CHF"}
                                ></ProducItme>
                            </div>
                            <div>
                                <ProducItme description={"Hallo Welt"}
                                            link={"https://www.codingfarm.org"}
                                            picture={logo}
                                            shop={"Avus"}
                                            titel={"Hallo Welt"}
                                            id={"uopizuzfjdhgfsg"}
                                            price={"20.- CHF"}
                                ></ProducItme>
                            </div>
                            <div>
                                <ProducItme description={"Hallo Welt"}
                                            link={"https://www.codingfarm.org"}
                                            picture={logo}
                                            shop={"Avus"}
                                            titel={"Hallo Welt"}
                                            id={"asfgdsfhhhrtjzesthezk"}
                                            price={"20.- CHF"}
                                ></ProducItme>
                            </div>
                            <div>
                                <ProducItme description={"Hallo Welt"}
                                            link={"https://www.codingfarm.org"}
                                            picture={logo}
                                            shop={"Avus"}
                                            titel={"Hallo Welt"}
                                            id={"szejtshnhsj"}
                                            price={"20.- CHF"}
                                ></ProducItme>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withCookies(productoverview)