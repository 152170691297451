import React from "react";
import ToolBox from "../helper/ToolBox";

var mytest = new ToolBox();

var baseUrl = process.env.REACT_APP_BACKEND_SERVER
export default class Basicinfos extends React.Component {

    componentDidMount() {
        console.log("Es sollte gehen")
        if (mytest.isFeatureAllowed('REACT_APP_FEATURE_ACCOUNT=false\n')) {
            console.log("Es sollte gehen")
            fetch(baseUrl+"/admin/login")
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            items: result
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: false,
                            error
                        });
                    }
                )
        }
    }

    render() {
        return (
            <div className={"conainer"}>
                <form >
                    <div className="mb-3">
                        <label htmlFor="affiliatetype" className="form-label">Affiliate Type</label>
                        <select id= "affiliatetype" className="form-select form-select-sm mb-3" aria-label=".form-select-lg example">
                        <option defaultValue={"CJ"}>Open this select menu</option>
                        <option value="CJ">CJ</option>
                        <option value="AWIN">AWIN</option>
                        <option value="Shop37">Shop37</option>
                    </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Default file input example</label>
                        <input className="form-control" type="file" id="formFile"/>
                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                            <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        )
    }
}