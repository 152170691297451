import React from 'react'
import {Cookies, ReactCookieProps, withCookies} from "react-cookie";
import {instanceOf} from "prop-types";
import {Redirect} from "react-router-dom";

class login extends React.Component {


    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    constructor(props: ReactCookieProps) {
        super(props);
        const { cookies } = props;
        cookies?.remove("usrprops")
        this.state = {
            usrprops: 'gugus'
        };
    }

    render() {
        return (<Redirect to="/" />);
    }
}

export default withCookies(login)