import React from 'react'
import logo from "../pic/shop37_474.png";

export default class teilnahmebedingungen extends React.Component {
    render() {
        return (
            <div className={"container"}>

                <div className={"row"}>
                    <div className="shadow p-3 mb-5 bg-body rounded">
                        <div className={"col"}>
                            <img
                                className={"img-fluid"}
                                src={logo}
                                alt="First slide"
                                height={150}
                                width={150}
                            /> <h1>Teilnahmebedingungen</h1>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col text-start"}>
                        <div className="shadow p-3 mb-5 bg-body rounded">
                            <div className={"h3"}>Teilnahmebedingungen für die Chance auf Kaufpreisschenkung durch
                                shop37
                            </div>
                            <div>I. Allgemeines Teilnahmevoraussetzungen<br/>
                                Zu diesen Teilnahmebedingungen gelten ergänzend unsere AGB. Eine Teilnahme an einer
                                Auslosung zur Kaufpreisschenkung findet nur wirksam statt, wenn der Teilnehmer zuvor
                                diese Teilnahmebedingungen und die AGB von shop37 durch jeweiligen opt-in ausdrücklich
                                anerkannt hat.
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col text-start"}>
                        <div className="shadow p-3 mb-5 bg-body rounded">
                            <div>
                                II. Konkrete Teilnahmevoraussetzungen
                                <ol>
                                    <li>Die Anmeldung zur Teilnahme an der Auslosung zur Kaufpreisschenkung muss
                                        in
                                        der von shop37 vorgegebenen Eingabemaske vollständig, fehlerfrei und mit
                                        wahrheitsgemäßen Angaben abgeschlossen und durch eine
                                        Teilnahmebestätigungs-Mail von shop37 abgeschlossen sein.
                                    </li>
                                    <li>Durch einen über das portal shop37 vermittelten Kauf bei einem Anbieter
                                        von
                                        Waren und/oder Dienstleistungen, der vom Teilnehmer mit dem
                                        Teilnahmewunsch
                                        an der Auslosung zur Kaufpreisschenkung ausgelöst wurde, erhält der
                                        Teilnehmer eine Platzziffer von 0-36 an einer noch nicht geschlossenen
                                        Auslosung.
                                    </li>
                                    <li>Die jeweiligen Auslosungen werden in Lostöpfen vollzogen, die von shop37
                                        einer jeweiligen Kaufpreiskategorie zugeordnet werden. Die Gestaltung
                                        der
                                        Lostöpfe nach Kaufpreiskategorien ist ausschließlich shop37 vorbehalten
                                        und
                                        kann nach jeweils abgeschlossener Auslosung von shop37 neu bestimmt
                                        werden.
                                    </li>
                                    <li>Die jeweilige Ausspielung wird ausgeführt, sobald alle 37 Platzziffern
                                        durch
                                        Teilnehmer nach dem oben beschriebenen Verfahren belegt sind.
                                    </li>
                                    <li>Die Auslosung wird und kann von shop37 nicht beeinflusst werden. Sie
                                        erfolgt
                                        nach einem vollautomatischen Zufallsverfahren.
                                    </li>
                                    <li>Sobald ein Teilnehmer gelost wurde, wird er von shop37 per mail über
                                        eine
                                        bedingte Kaufpreisschenkung informiert.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col text-start"}>
                        <div className="shadow p-3 mb-5 bg-body rounded">
                            <div>
                                III. Auszahlung des Kaufpreises<br/>
                                Der gezogene Teilnehmer erhält nach II. 6. die Nachricht über die bedingte
                                Kaufpreisschenkung durch shop37. Die Schenkung wird aber erst fällig und
                                zahlbar,
                                wenn der Teilnehmer den Kauf- oder Dienstleistungsvertrag mit dem durch shop37
                                vermittelten Anbieter vollständig erfüllt hat, also den Kaufpreis nach dort
                                gezahlt
                                hat und über keine Rückabwicklungsrechte (z.B. Anfechtung, Widerruf, Rücktritt,
                                vorzeitige Kündigung) mehr verfügt bzw. nicht mehr von diesem Gebrauch macht.
                                Shop37
                                unterstellt zu Gunsten des Teilnehmers die Fälligkeit der Kaufpreisschenkung
                                nach
                                Ablauf von 4 Wochen nach der Auslosung für Kaufverträge, für
                                Dienstleistungsverträge
                                von 4 Wochen nach vollständiger Erfüllung der Dienstleistung durch den
                                Dienstnehmer.
                                Die Auszahlung des Schenkungsbetrages erfolgt über das vom Teilnehmer bei seiner
                                Anmeldung oder später zuletzt angegebene Zahlungsmedium. Mit der Auszahlung des
                                Kaufpreises an den Teilnehmer ist der Vorgang beendet und es bestehen sohin
                                wechselseitig zwischen shop37 und dem Teilnehmer keinerlei Ansprüche mehr
                                hieraus.
                                Ausgenommen sind Erstattungsansprüche von shop37 im Falle einer Betrugshandlung
                                des
                                Teilnehmers zu Lasten von shop37.
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col text-start"}>
                        <div className="shadow p-3 mb-5 bg-body rounded">
                            <div>
                                Shop37 Teilnahmebedingungen, Stand: März 2022
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
