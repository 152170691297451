import React from 'react'

export default class ueberuns extends React.Component {
    render() {
        return (
            <div className={"container-fluid"}>
                <div className={"row align-items-start"}>
                    <div className={"col-md-2"}> </div>
                    <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}><h1>Über uns</h1></div>
                    <div className={"col-md-2"}> </div>
                </div>
               <div className={"row align-items-start"}>
                    <div className={"col-md-2"}> </div>
                    <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}>
                        <p className={"h4"}>Shop37 schafft Mehrwert</p>
                        <p>Wir sind ein junges Unternehmen, das für die Menschen Mehrwert und ein noch nicht bekanntes Shoppinggefühl im Internet schaffen möchte.</p>
                        <p>Wir verstehen dich nicht als Kunde, sondern als Teilnehmer. Wenn du irgendetwas im Netz suchst, dann sollst du die Möglichkeit haben, als einer von 37 Teilnehmern pro Preisgruppe deinen an den Verkäufer gezahlten Kaufpreis von uns zu 100 % geschenkt zu erhalten. Wie das genau funktioniert, kannst du in unseren Teilnahmebedingungen <a href={"https://www.Shop37.de"}>www.Shop37.de</a> studieren.
                        </p>
                        </div>
                    <div className={"col-md-2"}> </div>
               </div>
               <div className={"row align-items-start"}>
                    <div className={"col-md-2"}></div>
                    <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}>
                        <p className={"h4"}>Shop37 schafft Transparenz</p>
                        <p>Wir verstehen uns als ein online-Vergleichsportal für Produkte und Dienstleistungen, welches dir die Möglichkeit schaffen soll, eines Tages möglichst alle Produkte und Dienstleistungen über dieses Portal recherchieren zu können, um anhand dieser Daten das Beste für dich zu finden.</p>
                    </div>
                    <div className={"col-md-2"}></div>
               </div>
               <div className={"row align-items-start"}><div className={"col-md-2"}> </div></div>
               <div className={"row align-items-start"}>
                    <div className={"col-md-2"}></div>
                    <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}><p className={"h4"}>Shop37 schafft environment social governance (esg)</p>
                        <p>Nach Ablauf unserer Startphase wollen wir dir in naher Zukunft die weitere Möglichkeit eröffnen, den von uns geschenkten Kaufpreis ganz oder teilweise in gewissenhaft ökologische Projekte zu investieren, um damit einen Beitrag zu leisten, auch zukünftigen Generationen ein Leben in einer intakten Umwelt im Einklang mit der Natur zu gewähren.</p>
                    </div>
                    <div className={"col-md-2"}></div>
               </div>
               <div className={"row align-items-start"}><div className={"col-md-2"}> </div></div>
               <div className={"row align-items-start"}>
                    <div className={"col-md-2"}></div>
                    <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}><p className={"h4"}>Shop37 schafft Neutralität</p>
                        <p>Wir geben dir die Möglichkeit, sich neutral über alle Produkte und Dienstleistungen zu informieren, die deinem Interesse entsprechen. Denn wir verkaufen nicht selbst, wir vermitteln dich nur. Daher ist es uns wichtig, deinen Bedarf zu deinem höchstmöglichen Vorteil zu decken. Unsere Neutralität gibt dir Sicherheit.</p></div>
                    <div className={"col-md-2"}></div>
               </div>
               <div className={"row align-items-start"}><div className={"col-md-2"}> </div></div>
               <div className={"row align-items-start"}>
                    <div className={"col-md-2"}></div>
                    <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}><p className={"h4"}>Shop37 schafft Unabhängigkeit</p>
                        <p>Wir verkaufen nichts. Deshalb meinen wir auch, nicht käuflich zu sein. Weder beim Preisvergleich noch beim Verschenken des Kaufpreises lassen wir uns beeinflussen. Alles geschieht durch eine Software, die für die Bestimmung des zu Beschenkenden einen Zufallsalgorithmus verwendet.</p>
                    </div>
                    <div className={"col-md-2"}></div>
               </div>
               <div className={"row align-items-start"}>
                    <div className={"col-md-2"}> </div>
                    <div className={"col-md-8 text-start shadow p-3 mb-5 bg-body rounded"}>
                        <p className={"h4"}>Shop 37 wächst</p>
                        <p>Unser Konzept kann nur dann für alle Teilnehmenden ideal sein, wenn die Anzahl der Teilnehmenden möglichst hoch ist. Mit diesem Link kannst du auf unsere Seite aufmerksam machen und unsere Vorstellung für ein neues Verständnis des fairen und nachhaltigen Konsums werben: <a href={"https://www.Shop37.de"}>www.Shop37.de</a></p>
                    </div>
                    <div className={"col-md-2 "}> </div>

               </div>
            </div>
        );
    }
}