import React from 'react'

type ProductModalProps = {
  modetitel: string;
  modpicture: string;
  moddescription: string;
  modlink: string;
  modshop: string;
  modid:string;
  modprice:string;
}

const imageStely = {
  width: '400px',
  height: 'auto',
};

export default class productDetailModal extends React.Component<ProductModalProps> {

  render() {

    return (
        <div>
          <button type="button" className="btn btn-link" data-bs-toggle="modal" data-bs-target={"#"+this.props.modid}>
            Zu den Produkdetails
          </button>

          <div className="modal fade" id={this.props.modid}  aria-labelledby={this.props.modid+"Label"}
               aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id={this.props.modid+"Label"}>{this.props.modetitel}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className={"align-middle"}><img style={imageStely} src={this.props.modpicture}
                                                       className="card-img-top"
                                                       alt="Logo"></img></div>
                  <div>{this.props.moddescription}</div>
                </div>
                <div className="modal-footer">
                  <div className={"h4 align-content-start"}>Preis : {this.props.modprice}</div>
                  <a href={this.props.modlink} target={"_blank"} className="btn btn-primary" rel="noreferrer">gehe zu : {this.props.modshop}</a>
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}
