import React from 'react';
import TopMenu from './main/menu';
import UeberUns from './sites/ueberuns';
import SoGehts from './sites/sogehts';
import Login from './sites/login';
import Agb from './sites/agb';
import UserInfo from './sites/userinfo';
import Logout from './sites/logout';
import RegistForm from './sites/registrationform'
import Impressum from './sites/impressum';
import TeilnahmeBedingung from './sites/teilnahmebedingungen'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Switch, Route} from 'react-router';
import {BrowserRouter, Redirect} from "react-router-dom";
import {useCookies, withCookies} from "react-cookie";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Productoverview from "./sites/productoverview";
import Registration from "./sites/registration";
import Basicinfos from "./sites/basicinfos";


function App() {
    const [cookies] = useCookies(['usrprops']);

    return (
        <div className="App">
            <BrowserRouter>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <TopMenu/>
                            <Switch>
                                <Route path="/register"><Registration/></Route>
                                {cookies.usrprops?.length > 0 &&
                                    <Route path="/logout"><Logout/></Route>
                                }
                            </Switch>

                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col"}>

                            <Switch>
                                <Route exact={true} path="/"><Productoverview/></Route>
                                <Route path="/sogehts"><SoGehts/></Route>
                                <Route path={"/uebueruns"}><UeberUns/></Route>
                                <Route path="/products"><Productoverview/></Route>
                                <Route path="/login"><Login/></Route>
                                <Route path="/registerform"><RegistForm/></Route>
                                <Route path="/agb"><Agb/></Route>
                                <Route path="/impressum"><Impressum/></Route>
                                <Route path="/basicinfos"><Basicinfos/></Route>
                                <Route path="/teilnahme" component={TeilnahmeBedingung}/>
                                {cookies.usrprops?.length > 0 &&
                                    <Route path="/userinfo"><UserInfo/></Route>
                                }
                                {cookies.usrprops?.length > 0 &&
                                    <Route path="/logout"><Logout/> </Route>
                                }
                                <Route component={() => <Redirect to="/"/>}/>
                            </Switch>

                    </div>
                </div>
            </div>
            </BrowserRouter>
        </div>
    );
}

export default withCookies(App);
