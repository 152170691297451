const REACT_APP_FEATURE_ACCOUNT=false;
const REACT_APP_FEATURE_SHOP=true;
const REACT_APP_FEATURE_POOL_GAME=false;
const REACT_APP_SEARCH = true;

export default class ToolBox{

    isFeatureAllowed(featureName : string): boolean {
/*        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "" || window.location.hostname === "codingfarm-dockerserver") {
            return true;
        }else{*/
            switch (featureName){
                case "REACT_APP_FEATURE_ACCOUNT":
                    return REACT_APP_FEATURE_ACCOUNT;
                case "REACT_APP_FEATURE_SHOP":
                    return REACT_APP_FEATURE_SHOP;
                case "REACT_APP_FEATURE_POOL_GAME":
                    return REACT_APP_FEATURE_POOL_GAME;
                case "REACT_APP_SEARCH":
                    return REACT_APP_SEARCH;
                default:
                    return false;
            }
/*        }
        return false;*/
    }
}