import React from "react";
import ToolBox from "../helper/ToolBox";


type CategorieViewState = {
    isLoaded: boolean;
    items: [];
    error: string;
    oldProp: string;
}

interface CategorieProps {
    onChangeCat : (e: React.ChangeEvent<HTMLSelectElement>) => void;
    brand: string;
}
var toolbox = new ToolBox()
var baseUrl = process.env.REACT_APP_BACKEND_SERVER
export default class CategorieSelector extends React.Component <CategorieProps, CategorieViewState> {

    constructor(props: CategorieProps) {
        super(props);
        this.state = {
            error: "", items: [], isLoaded: false, oldProp:""};
    }
    
    reloadListe = () => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: this.props.brand
        };
        if (toolbox.isFeatureAllowed('REACT_APP_FEATURE_SHOP')) {
            fetch(baseUrl + "/categorie/menu", requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result)
                        this.setState({
                            isLoaded: true,
                            items: result
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: false,
                            error
                        });
                    }
                )

        };
    }


    componentDidMount() {

            this.reloadListe();

    }

    componentDidUpdate(prevProps: Readonly<CategorieProps>, prevState: Readonly<CategorieViewState>, snapshot?: any) {
        if (this.state.oldProp !== this.props.brand) {
            this.reloadListe();
            this.setState({
                oldProp: this.props.brand
            });
        }
    }

    render() {
        return (
            <div>
                <form className={"form-floating"}>
                    <select id={"brandSelect"} className="form-select form-select-sm" aria-label=".form-select-sm example" onChange={this.props.onChangeCat}>
                        <option value={""}>Alles</option>
                        {toolbox.isFeatureAllowed('REACT_APP_FEATURE_SHOP') ?
                            this.state.isLoaded && this.state.items.length > 0 ?
                                this.state.items.map(item => <option key={item} value={item}>{item}</option> ) : null : <option value={"no brands"}>No Brands</option>
                        }
                    </select>
                    <label htmlFor={"brandSelect"}>Wähle die Kategorie</label>
                </form>
            </div>)
    }
}